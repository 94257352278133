import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget } from "../../";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedVideo {
    videoWebm: string;
    videoOgv: string;
    videoMp4: string;
    locale: string;
    videoEmbedScript: string;
}
export interface WidgetOptions {
    styleIds: any[];
    videoSource: boolean;
    videoExternalUse: boolean;
    videoWidth: string;
    videoHeight: string;
    videoShowControls: boolean;
    videoLoop: boolean;
    videoAutoPlay: boolean;
    localized: LocalizedVideo[];
    videoWebm: string;
    videoOgv: string;
    videoMp4: string;
    videoEmbedScript: string;
}

const TARGETS = ["video"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "video-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetVideo, "videoWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetVideo, "videoWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetVideo, "general"),
                    properties: [
                        // {
                        // will be used later on when we support it -> type: "video",
                        // eslint-disable-next-line max-len
                        // will be used later on when we support it -> label: getI18nLocaleObject(namespaceList.widgetVideo, "video"),
                        // will be used later on when we support it -> variable: "VideoSpec",
                        // },
                        [
                            {
                                variable: "videoSource",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoSource"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                visible: (options: WidgetOptions) => options.videoSource,
                                tabContent: [
                                    {
                                        variable: "videoWebm",
                                        label: getI18nLocaleObject(namespaceList.widgetVideo, "videoWebm"),
                                        type: "text",
                                    },
                                    {
                                        variable: "videoOgv",
                                        label: getI18nLocaleObject(namespaceList.widgetVideo, "videoOgv"),
                                        type: "text",
                                    },
                                    {
                                        variable: "videoMp4",
                                        label: getI18nLocaleObject(namespaceList.widgetVideo, "videoMp4"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "videoWebm",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoWebm"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.videoSource,
                            },
                            {
                                variable: "videoOgv",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoOgv"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.videoSource,
                            },
                            {
                                variable: "videoMp4",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoMp4"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.videoSource,
                            },
                            {
                                variable: "videoExternalUse",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoExternalUse"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                visible: (options: WidgetOptions) => options.videoExternalUse,
                                tabContent: [
                                    {
                                        variable: "videoEmbedScript",
                                        label: getI18nLocaleObject(namespaceList.widgetVideo, "videoEmbedScript"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "videoEmbedScript",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoEmbedScript"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.videoExternalUse,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetVideo, "videoExtra"),
                    properties: [
                        [
                            {
                                variable: "videoWidth",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoWidth"),
                                type: "number",
                            },
                            {
                                variable: "videoHeight",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoHeight"),
                                type: "number",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "descriptionVideoControls"),
                            },
                            {
                                variable: "videoShowControls",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoShowControls"),
                                type: "checkbox",
                            },
                            {
                                variable: "videoLoop",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoLoop"),
                                type: "checkbox",
                            },
                            {
                                variable: "videoAutoPlay",
                                label: getI18nLocaleObject(namespaceList.widgetVideo, "videoAutoPlay"),
                                type: "checkbox",
                            },
                        ],
                    ],
                    visible: (options: WidgetOptions) => options.videoSource,
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetVideo, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const videoWidget: PageWidgetSpec<WidgetOptions> = {
    id: "video",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetVideo, "videoWidget"),
    description: getI18nLocaleObject(namespaceList.widgetVideo, "videoWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        videoSource: false,
        localized: [],
        videoExternalUse: false,
        videoWidth: "",
        videoHeight: "",
        videoShowControls: true,
        videoLoop: false,
        videoAutoPlay: false,
        videoWebm: "",
        videoOgv: "",
        videoMp4: "",
        videoEmbedScript: "",
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const width = `width=${widget.options.videoWidth}`;
        const height = `height=${widget.options.videoHeight}`;
        const hideWidget = getHideWidgetClass(widget.options, !isClientLoggedIn());
        if (hideWidget === null) {
            return <div />;
        }
        const localVideoContent = widget.options.localized ? widget.options.localized.find((lc) => lc.locale === context.currentLocale.locale) : null;
        if (widget.options.videoExternalUse) {
            return (
                // eslint-disable-next-line max-len
                <div dangerouslySetInnerHTML={{ __html: localVideoContent && localVideoContent.videoEmbedScript ? localVideoContent.videoEmbedScript : widget.options.videoEmbedScript }} />
            );
        }
        return (
            <video
                className={`video-playback ${hideWidget}`}
                preload="true"
                width={widget.options.videoWidth ? width : "100%"}
                height={widget.options.videoHeight ? height : undefined}
                controls={widget.options.videoShowControls}
                loop={widget.options.videoLoop}
                autoPlay={widget.options.videoAutoPlay}
                muted={widget.options.videoAutoPlay}
            >
                <source src={`${localVideoContent && localVideoContent.videoWebm ? localVideoContent.videoWebm : widget.options.videoWebm}`} type='video/webm; codecs="vp8.0, vorbis"' />
                <source src={`${localVideoContent && localVideoContent.videoOgv ? localVideoContent.videoOgv : widget.options.videoOgv}`} type='video/ogg; codecs="theora, vorbis"' />
                <source src={`${localVideoContent && localVideoContent.videoMp4 ? localVideoContent.videoMp4 : widget.options.videoMp4}`} type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' />
            </video>
        );
    },
};
